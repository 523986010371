.silver {
  color: #a5a5a5;
}

.mid-grey {
  color: #b9b9b9;
}

.grey {
  color: #646464;
}

.green {
  color: #73bc9e;
}

.orange {
  color: #e1624b;
}

.green-bg {
  background: #73bc9e;
  color: #fff;
}

.orange-bg {
  background: #e1624b;
  color: #fff;
}

body {
  font-family: "Helvetica", "Arial", sans-serif;
  font-size: 18px;
  color: #000;
  font-weight: 400;
  line-height: 1.2;
}

@keyframes fadeout {
  to {
    color: #000;
    background-color: transparent;
  }
}
/**
 * ----------------------------------------
 * animation wobble
 * ----------------------------------------
 */
@-webkit-keyframes wobble {
  0%, 100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-15px) rotate(-3deg);
    transform: translateX(-15px) rotate(-3deg);
  }
  30% {
    -webkit-transform: translateX(8px) rotate(3deg);
    transform: translateX(8px) rotate(3deg);
  }
  45% {
    -webkit-transform: translateX(-8px) rotate(-1.5deg);
    transform: translateX(-8px) rotate(-1.5deg);
  }
  60% {
    -webkit-transform: translateX(5px) rotate(1.2deg);
    transform: translateX(5px) rotate(1.2deg);
  }
  75% {
    -webkit-transform: translateX(-3px) rotate(-1deg);
    transform: translateX(-3px) rotate(-1deg);
  }
}
@keyframes wobble {
  0%, 100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-15px) rotate(-3deg);
    transform: translateX(-15px) rotate(-3deg);
  }
  30% {
    -webkit-transform: translateX(8px) rotate(3deg);
    transform: translateX(8px) rotate(3deg);
  }
  45% {
    -webkit-transform: translateX(-8px) rotate(-1.5deg);
    transform: translateX(-8px) rotate(-1.5deg);
  }
  60% {
    -webkit-transform: translateX(5px) rotate(1.2deg);
    transform: translateX(5px) rotate(1.2deg);
  }
  75% {
    -webkit-transform: translateX(-3px) rotate(-1deg);
    transform: translateX(-3px) rotate(-1deg);
  }
}
/**
 * ----------------------------------------
 * animation rotate-90-cw
 * ----------------------------------------
 */
@-webkit-keyframes rotate-90-cw {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}
@keyframes rotate-90-cw {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}
header#header {
  padding: 8px 0;
  /*
    .color-hint {
      color: $orange;
    }
    .color-found {
      color: $green;
    }
    .color-hint {
      color: $orange;
    }
    */
}
header#header div.header-wrap {
  display: flex;
  align-items: center;
}
header#header div.header-logo {
  flex: 0 0 auto;
  width: 30%;
}
header#header div.header-logo img {
  display: block;
  width: auto;
  height: 36px;
  max-width: 100%;
  object-fit: contain;
  object-position: left;
}
header#header div.stats-wrap {
  flex: 0 0 auto;
  width: 40%;
  display: flex;
  justify-content: center;
}
header#header div.stats-wrap span.icon-wrap, header#header div.stats-wrap span.stat-container {
  display: inline-block;
  vertical-align: middle;
}
header#header div.stats-wrap span.stat-container {
  padding-left: 4px;
  font-size: 20px;
}
header#header div.info-wrap {
  flex: 0 0 auto;
  width: 30%;
  display: flex;
  justify-content: flex-end;
}
header#header ul.header-icon-list {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}
header#header ul.header-icon-list li + li {
  margin-left: 15px;
}
header#header button.header-btn {
  background: transparent;
  padding: 0;
  margin: 0;
  display: block;
  border: none;
  outline: none;
}
header#header button.header-btn span.icon-wrap {
  font-size: 26px;
}
header#header button.header-btn span.icon-wrap img {
  width: 24px;
  height: 24px;
}
@media (max-width: 1200px) {
  header#header div.header-logo {
    width: 25%;
  }
  header#header div.header-logo img {
    height: 24px;
  }
  header#header div.stats-wrap {
    width: 50%;
    justify-content: center;
  }
  header#header div.stats-wrap span.stat-container {
    padding-left: 4px;
    font-size: 14px;
    font-weight: 600;
  }
  header#header div.info-wrap {
    width: 25%;
  }
  header#header ul.header-icon-list li + li {
    margin-left: 10px;
  }
  header#header button.header-btn span.icon-wrap {
    font-size: 14px;
  }
  header#header button.header-btn span.icon-wrap img {
    width: 20px;
    height: 20px;
  }
}
@media (max-width: 767px) {
  header#header div.stats-wrap {
    padding-right: 0;
  }
}
@media (max-width: 320px) {
  header#header div.header-logo {
    width: 25%;
  }
  header#header div.stats-wrap {
    width: 55%;
    padding-left: 10px;
  }
  header#header ul.header-icon-list li + li {
    margin-left: 8px;
  }
  header#header button.header-btn span.icon-wrap img {
    width: 18px;
    height: 18px;
  }
}
header#header.default-header div.header-logo {
  width: 100% !important;
}
header#header.default-header button#backBtn {
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;
  outline: none !important;
}
header#header.default-header button#backBtn i {
  color: #000;
  font-size: 24px;
}
header#header.default-header button#backBtn:hover i, header#header.default-header button#backBtn:focus i, header#header.default-header button#backBtn:active i {
  color: #b9b9b9;
}

footer#footer {
  padding-top: 40px;
}
footer#footer div.footer-logo img {
  display: block;
  width: auto;
  height: 24px;
  max-width: 100%;
  object-fit: contain;
  object-position: left;
}
footer#footer p.copyright {
  margin-top: 20px;
  font-size: 16px;
  color: #b9b9b9;
}
@media (max-width: 320px) {
  footer#footer div.footer-nav ul li {
    font-size: 16px;
  }
}

.sidebar {
  padding-left: 60px;
  padding-right: 40px;
}
@media (max-width: 991px) {
  .sidebar {
    padding: 0;
  }
}

a.link:hover, a.link:visited, a.link:active {
  display: inline;
  color: #000;
  padding: 0px;
  margin: 0px;
  text-decoration: none;
}

.btn {
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border: none;
  outline: solid 1px transparent;
  color: #fff;
  background: #646464;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-size: 18px;
}
.btn:hover, .btn:focus, .btn:active {
  outline: solid 1px #000 !important;
  background: #000;
  color: #fff;
}
.btn.btn-white {
  background: #f0f0f0;
  border: solid 1px #646464;
  color: #000;
  font-weight: 500;
}
.btn.btn-white:hover, .btn.btn-white:focus, .btn.btn-white:active {
  outline: none !important;
  background: #000;
  color: #fff;
  border-color: #000;
}
.btn.btn-small {
  padding: 2px 15px;
}

.footer-nav ul {
  display: block;
  list-style: none;
  padding: 0;
  margin: 0;
}
.footer-nav ul li {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}
.footer-nav ul li a {
  font-weight: 600;
  color: #000;
  text-decoration: underline;
  text-decoration-color: transparent;
  -webkit-transition: text-decoration-color 0.3s ease-in-out;
  -moz-transition: text-decoration-color 0.3s ease-in-out;
  -o-transition: text-decoration-color 0.3s ease-in-out;
  transition: text-decoration-color 0.3s ease-in-out;
}
.footer-nav ul li a:hover, .footer-nav ul li a:focus, .footer-nav ul li a:active {
  text-decoration-color: #000;
}
.footer-nav ul li + li {
  padding-left: 10px;
}
.footer-nav ul li + li:before {
  content: "|";
  position: absolute;
  left: 0;
  top: 0;
  color: #b9b9b9;
}

@media (max-width: 767px) {
  .modal-backdrop {
    display: none !important;
  }
}
p.section-info {
  padding: 5px 0;
  font-size: 18px;
}

div.modal div.modal-dialog {
  max-width: 80%;
}
div.modal div.modal-content {
  padding: 0;
  margin: 0 auto;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
}
div.modal div.modal-header {
  background: #000;
  padding: 4px 10px;
  color: #fff;
  position: relative;
}
div.modal div.modal-header.tries, div.modal div.modal-header.hints, div.modal div.modal-header.stats {
  padding: 4px 10px;
}
div.modal div.modal-header.tries h3, div.modal div.modal-header.hints h3, div.modal div.modal-header.stats h3 {
  font-size: 30px;
  line-height: normal;
}
div.modal div.modal-header.tries {
  background: #a5a5a5;
}
div.modal div.modal-header.hints {
  background: #e1624b;
}
div.modal div.modal-header.stats {
  background: #73bc9e;
}
div.modal div.modal-header h3 {
  font-size: 30px;
  padding-right: 30px;
}
div.modal div.modal-header h3.icon img {
  width: 30px;
  height: 30px;
  display: inline-block;
  vertical-align: middle;
}
div.modal div.modal-header h3.icon span {
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 30px);
  padding-left: 10px;
}
div.modal div.modal-header button.close {
  position: absolute;
  top: 50%;
  right: 10px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: 0;
  padding: 0;
  opacity: 0.85;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=85)";
  filter: alpha(opacity=85);
}
div.modal div.modal-header button.close:hover, div.modal div.modal-header button.close:focus, div.modal div.modal-header button.close:active {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
}
div.modal div.modal-header button.close img {
  width: 20px;
  height: 20px;
}
div.modal div.modal-body {
  padding: 20px 10px 0;
}
div.modal div.modal-body div.modal-section {
  position: relative;
}
div.modal div.modal-body div.modal-section + div.modal-section {
  margin-top: 10px;
  padding: 20px 0 10px 0;
}
div.modal div.modal-body div.modal-section + div.modal-section:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: #f0f0f0;
}
div.modal div.modal-body div.modal-section p {
  margin-bottom: 0;
}
div.modal div.modal-body h4 {
  font-size: fs-header;
  font-weight: 600;
  margin: 0 0 16px;
}
div.modal div.modal-body h4.icon img {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  display: inline-block;
}
div.modal div.modal-body h4.icon span {
  display: inline-block;
  width: calc(100% - 20px);
  padding-left: 10px;
  vertical-align: middle;
}
div.modal div.modal-body .layout {
  border: 1px solid lightcoral;
}
div.modal div.modal-body .layout-blue {
  border: 1px solid lightblue;
}
div.modal div.modal-body .demo_search {
  display: inline-block;
  background-color: #f0f0f0;
  border: 1px solid #646464;
  padding: 5px 20px 5px 5px;
  font-family: monospace, monospace;
}
div.modal div.modal-body .demo_type {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  border-right: 2px solid #646464;
  width: 100%;
  animation: typewriter 7s steps(5, end) forwards infinite, cursor 1s infinite;
}
div.modal div.modal-body .demo_button {
  display: inline-block;
  border: 1px solid #646464;
  padding: 5px;
  background-color: #646464;
  color: #fff;
  animation: clicking 7s forwards infinite;
}
div.modal div.modal-body .demo_try {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}
@keyframes typewriter {
  0% {
    width: 0%;
  }
  20% {
    width: 100%;
  }
  100% {
    width: 100%;
  }
}
@keyframes cursor {
  0% {
    border-color: transparent;
  }
  50% {
    border-color: #646464;
  }
  100% {
    border-color: transparent;
  }
}
@keyframes clicking {
  0% {
    background-color: #646464;
  }
  35% {
    background-color: #646464;
  }
  36% {
    background-color: #000;
  }
  40% {
    background-color: #000;
  }
  41% {
    background-color: #646464;
  }
  100% {
    background-color: #646464;
  }
}
div.modal div.modal-body .demo-filled {
  background: #fff;
  color: #000;
  padding: 3px;
  line-height: 2em;
  letter-spacing: 2px;
}
div.modal div.modal-body .demo:before {
  content: "b__ks.";
  background: #f0f0f0;
  padding: 3px;
  line-height: 2em;
  letter-spacing: 2px;
  animation: demo_hide_blanks 7s forwards infinite;
}
@keyframes demo_hide_blanks {
  0% {
    content: "b__ks.";
    background: #f0f0f0;
    color: #000;
  }
  39% {
    content: "b__ks.";
    background: #f0f0f0;
    color: #000;
  }
  40% {
    content: "books.";
    background: #73bc9e;
    color: #fff;
  }
  60% {
    content: "books.";
    background: #73bc9e;
    color: #fff;
  }
  67% {
    content: "books.";
    background: #fff;
    color: #000;
  }
  100% {
    content: "books.";
    background: #fff;
    color: #000;
  }
}
div.modal div.modal-footer {
  border: none;
  position: relative;
  margin-top: 10px;
  padding: 12px 10px;
}
div.modal div.modal-footer:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: #f0f0f0;
}
@media (max-width: 767px) {
  div.modal.fade {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    top: -100vh;
    -webkit-transition: top 0.15s ease-in-out;
    -moz-transition: top 0.15s ease-in-out;
    -o-transition: top 0.15s ease-in-out;
    transition: top 0.15s ease-in-out;
  }
  div.modal.fade.in {
    top: 0;
  }
  div.modal div.modal-dialog {
    max-width: none;
    width: 100%;
    margin: 0 auto;
    min-height: 100vh;
  }
  div.modal div.modal-content {
    border: none;
    min-height: 100%;
  }
}

div#welcome ul.difficulty-level, div#settings ul.difficulty-level {
  margin: 12px 0 12px 0;
}

div#tries-modal div.modal-body {
  padding-bottom: 20px;
}
div#tries-modal p.tries-modal-progress {
  margin-bottom: 10px;
}
div#tries-modal p.tries-words-heading {
  font-size: 16px;
}

div#hints-modal div.modal-body {
  padding-bottom: 20px;
}
div#hints-modal p.tries-modal-progress {
  margin-bottom: 10px;
}
div#hints-modal p.tries-words-heading {
  font-size: 16px;
}

div#stats-modal div.modal-body {
  padding-bottom: 20px;
}
div#stats-modal div.stats-container > div + div {
  margin-top: 15px;
}
div#stats-modal p.stat-value, div#stats-modal p.stat-label {
  display: inline-block;
  vertical-align: middle;
}
div#stats-modal p.stat-value {
  width: 94px;
  text-align: center;
  line-height: 94px;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  color: #fff;
  font-weight: 600;
  font-size: 30px;
}
div#stats-modal p.stat-label {
  width: calc(100% - 94px);
  padding-left: 10px;
}
div#stats-modal div.completed p.stat-value {
  background: #73bc9e;
}
div#stats-modal div.tries p.stat-value {
  background: #a5a5a5;
}
div#stats-modal div.hints p.stat-value {
  background: #e1624b;
}

div#information div.modal-footer {
  border: none;
  padding-top: 30px;
  margin-top: 0;
}
div#information div.modal-footer:before {
  display: none;
}
div#information nav {
  margin-top: 5px;
}
div#information div.copyright-wrap {
  display: flex;
}
div#information div.copyright-wrap > div {
  width: 50%;
  flex: 0 0 50%;
}
div#information div.copyright-wrap p {
  margin-bottom: 0;
  color: #b9b9b9;
  font-size: 16px;
}
div#information div.copyright-wrap p.copyright {
  text-align: left;
}

div#settings div.modal-body {
  padding-bottom: 20px;
}
div#settings p + .btn {
  margin-top: 10px;
}
div#settings div.modal-footer {
  border: none;
  padding-top: 30px;
  margin-top: 0;
}
div#settings div.modal-footer:before {
  display: none;
}
div#settings div.copyright-wrap {
  display: flex;
}
div#settings div.copyright-wrap > div {
  width: 50%;
  flex: 0 0 50%;
}
div#settings div.copyright-wrap p {
  margin-bottom: 0;
  color: #b9b9b9;
  font-size: 16px;
}
div#settings div.copyright-wrap p.copyright {
  text-align: left;
}

ul.share-links {
  margin: 0;
  padding: 0;
  margin-top: 5px;
  display: block;
  list-style: none;
  text-align: center;
}
ul.share-links li {
  display: inline-block;
  vertical-align: middle;
}
ul.share-links li + li {
  margin-left: 10px;
}
ul.share-links button {
  padding: 0;
  margin: 0;
  outline: none;
  border: none;
  background: transparent;
  display: block;
}
ul.share-links button img {
  width: 20px;
  height: 20px;
}

div#cta div.modal-body {
  padding-bottom: 20px;
}
div#cta div.modal-paragraph {
  padding-top: 10px;
}
div#cta p + .btn {
  margin-top: 10px;
}
div#cta div.modal-footer {
  border: none;
  padding-top: 30px;
  margin-top: 0;
}
div#cta div.modal-footer:before {
  display: none;
}
div#cta div.copyright-wrap {
  display: flex;
}
div#cta div.copyright-wrap > div {
  width: 50%;
  flex: 0 0 50%;
}
div#cta div.copyright-wrap p {
  margin-bottom: 0;
  color: #b9b9b9;
  font-size: 16px;
}
div#cta div.copyright-wrap p.copyright {
  text-align: left;
}
div#cta div.cta-share {
  padding: 12px 10px 0;
  margin-top: 12px;
}
div#cta div.cta-share p {
  margin-bottom: 0;
}
div#cta div.cta-share h4 {
  margin-top: 0;
}
div#cta div.cta-share img {
  width: 30px;
  height: 30px;
}

/**

  Winner modal:

    - Two styles:
      - Static gif
      - Dynamic gif
    - Type set by adding a class to the modal
      - Use static-gif to show the static gif
      - Or, use dynamic gif
 */
div#wwcd h3 {
  font-size: 40px;
  margin: 0;
  font-weight: 600;
}
div#wwcd h3 + p {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 20px;
}
div#wwcd div.modal-header {
  border: none;
  padding: 10px;
}
div#wwcd div.modal-header img.logo {
  display: block;
  width: auto;
  height: 24px;
}
div#wwcd ul.winner-stats {
  display: block;
  padding: 0;
  margin: 0;
  list-style: none;
}
div#wwcd ul.winner-stats li {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  text-align: center;
}
div#wwcd ul.winner-stats span.bubble {
  display: block;
  text-align: center;
  width: 94px;
  line-height: 94px;
  color: #fff;
  font-weight: 600;
  font-size: 30px;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  margin: 0 auto;
}
div#wwcd ul.winner-stats span.bubble.success-rate {
  background: #73bc9e;
}
div#wwcd ul.winner-stats span.bubble.hints-used {
  background: #e1624b;
}
div#wwcd ul.winner-stats span.stat-label {
  display: block;
  margin-top: 10px;
  text-align: center;
  font-weight: 500;
}
div#wwcd div.winner-gif-wrap img {
  width: 100%;
  height: 175px;
  object-fit: cover;
  object-position: center;
}
div#wwcd div.winner-share, div#wwcd div.support, div#wwcd div.thanks {
  padding: 12px 10px 0;
  margin-top: 12px;
}
div#wwcd div.winner-share p, div#wwcd div.support p, div#wwcd div.thanks p {
  margin-bottom: 0;
}
div#wwcd div.winner-share h4, div#wwcd div.support h4, div#wwcd div.thanks h4 {
  margin-top: 0;
}
div#wwcd div.thanks {
  padding-bottom: 30px;
}
div#wwcd div.support {
  border-top: 1px dotted #b9b9b9;
}
div#wwcd div.support a {
  text-decoration: underline;
  -webkit-transition: text-decoration-color 0.3s ease-in-out;
  -moz-transition: text-decoration-color 0.3s ease-in-out;
  -o-transition: text-decoration-color 0.3s ease-in-out;
  transition: text-decoration-color 0.3s ease-in-out;
}
div#wwcd div.support a:hover, div#wwcd div.support a:focus, div#wwcd div.support a:active {
  text-decoration-color: #000;
}
div#wwcd div.modal-static-gif {
  display: none;
}
div#wwcd div.modal-static-gif div.modal-header {
  background: #f8f9fb;
}
div#wwcd div.modal-static-gif div.modal-body {
  background: #f8f9fb;
}
div#wwcd div.modal-static-gif div.winner-share, div#wwcd div.modal-static-gif div.support, div#wwcd div.modal-static-gif div.thanks {
  background: #000;
  color: #fff;
  margin: 0 -10px;
}
div#wwcd div.modal-static-gif div.support a {
  color: #fff;
}
div#wwcd div.modal-static-gif div.support a:hover, div#wwcd div.modal-static-gif div.support a:focus, div#wwcd div.modal-static-gif div.support a:active {
  text-decoration-color: #fff;
}
div#wwcd div.modal-dynamic-gif div.modal-body {
  padding-top: 0;
}
div#wwcd div.modal-dynamic-gif div.winner-gif-wrap {
  margin: 0;
}
div#wwcd div.modal-dynamic-gif div.support a {
  color: #000;
}
div#wwcd div.modal-dynamic-gif div.support a:hover, div#wwcd div.modal-dynamic-gif div.support a:focus, div#wwcd div.modal-dynamic-gif div.support a:active {
  text-decoration-color: #000;
}
div#wwcd img {
  max-width: 80%;
  max-height: 150px;
  display: block;
  margin: auto;
}
div#wwcd .gif-container {
  width: 100%;
  height: auto;
  margin-bottom: 5px;
  margin-top: 5px;
  position: relative;
}
div#wwcd div.modal-dynamic-gif {
  display: none;
}
div#wwcd.static-gif div.modal-static-gif {
  display: block;
}
div#wwcd.dynamic-gif div.modal-dynamic-gif {
  display: block;
}

ul.modal-words {
  display: block;
  padding: 0;
  margin: 10px 0 0;
  list-style: none;
}
ul.modal-words li {
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px;
}
ul.modal-words li span.word {
  background: #f0f0f0;
  padding: 3px;
  line-height: 2em;
  letter-spacing: 2px;
  cursor: default;
}
ul.modal-words li span.highlight_found {
  background-color: #73bc9e;
  color: #fff;
}
ul.modal-words li span.cheated {
  color: #e1624b;
}
ul.modal-words li img {
  height: 2em;
  display: inline-block;
  vertical-align: middle;
}
ul.modal-words li.icon img {
  width: 1em;
  height: 1em;
  display: inline-block;
  vertical-align: middle;
}

ul.modal-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
ul.modal-list li {
  padding-left: 20px;
  position: relative;
}
ul.modal-list li:before {
  content: "";
  width: 12px;
  height: 12px;
  border: solid 1px #b9b9b9;
  display: block;
  position: absolute;
  top: 5px;
  left: 0;
}
ul.modal-list li + li {
  margin-top: 10px;
}

div.stats-wrapper {
  display: flex;
  flex-flow: row nowrap;
}
div.stats-wrapper .circular-chart {
  display: block;
  margin: 10px auto;
  max-width: 80%;
  max-height: 250px;
}
div.stats-wrapper .circle-bg {
  fill: none;
  stroke: #f0f0f0;
  stroke-width: 3.8;
}
div.stats-wrapper .circle {
  fill: none;
  stroke-width: 2.8;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}
@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}
div.stats-wrapper .circular-chart.red .circle {
  stroke: #d94c22;
}
div.stats-wrapper .circular-chart.green .circle {
  stroke: #73bc9e;
}
div.stats-wrapper .circular-chart.blue .circle {
  stroke: #3c9ee5;
}
div.stats-wrapper .percentage {
  fill: #b9b9b9;
  font-family: sans-serif;
  font-size: 0.5em;
  text-anchor: middle;
}
div.stats-wrapper .number {
  fill: #b9b9b9;
  font-family: sans-serif;
  font-size: 0.5em;
  text-anchor: middle;
}
div.stats-wrapper .single-chart {
  width: 33%;
  justify-content: space-around;
}

.bigger {
  font-size: 1.15em;
}

.table-instruction {
  padding-top: 10px;
  text-align: center;
}

div.share {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
}
div.share div.sharebtn {
  margin: 0.25em;
  padding: 0;
}
div.share div.sharebtn:after {
  content: "";
  clear: both;
  display: table;
}
div.share div.sharebtn button {
  background: transparent;
  font-size: 2em;
  border: none;
  cursor: pointer;
}
div.share div.sharebtn button:hover {
  color: #f0f0f0;
}

.underline {
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 10px;
}

.content-wrap a:not(.btn) {
  color: #000;
  font-weight: 500;
  text-decoration: underline;
  text-decoration-color: transparent;
  -webkit-transition: text-decoration-color 0.3s ease-in-out;
  -moz-transition: text-decoration-color 0.3s ease-in-out;
  -o-transition: text-decoration-color 0.3s ease-in-out;
  transition: text-decoration-color 0.3s ease-in-out;
}
.content-wrap a:not(.btn):hover, .content-wrap a:not(.btn):focus, .content-wrap a:not(.btn):active {
  text-decoration-color: #000;
}
.content-wrap ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.content-wrap ul li {
  padding-left: 20px;
  position: relative;
}
.content-wrap ul li:before {
  content: "";
  width: 12px;
  height: 12px;
  border: solid 1px #b9b9b9;
  display: block;
  position: absolute;
  top: 5px;
  left: 0;
}
.content-wrap ul li + li {
  margin-top: 10px;
}

body.home div#rotate-notice {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: -1;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  display: none;
  text-align: center;
}
body.home div#rotate-notice img.rotate-img {
  width: 100%;
  max-width: 90px;
  margin: 0 auto;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
body.home div#rotate-notice img.rotate-img.rotate {
  -webkit-animation: rotate-90-cw 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: rotate-90-cw 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
body.home div#rotate-notice p {
  margin-top: 15px;
}
body.home div.container.game-container {
  max-width: 450px;
}
body.home div.keyboard-wrapper.kb-hidden {
  display: none;
}
body.home div.intro-wrapper {
  background: #fff;
}
body.home div.intro-wrapper p#intro {
  color: #000;
  padding: 10px 5px;
  margin: 0;
  font-size: 16px;
}
@media (max-width: 991px) {
  body.home div.intro-wrapper p#intro {
    font-size: 14px;
  }
}
body.home div.intro-wrapper p#intro a {
  color: #3c9ee5;
  text-decoration: underline;
  text-decoration-color: #fff;
}
body.home div.intro-wrapper p#intro strong {
  font-style: italic;
  font-weight: 500;
}
body.home div.attribution-wrapper {
  text-align: right;
  background: #fff;
  padding: 10px 20px;
}
body.home div.attribution-wrapper p#attribution {
  color: #000;
  padding: 10px 5px;
  margin: 0;
  font-size: 16px;
}
@media (max-width: 991px) {
  body.home div.attribution-wrapper p#attribution {
    font-size: 14px;
  }
}
body.home div.attribution-wrapper p#attribution a {
  color: #3c9ee5;
  text-decoration: underline;
  text-decoration-color: #fff;
}
body.home div.attribution-wrapper p#attribution strong {
  font-style: italic;
  font-weight: 500;
}
body.home div.announce-wrapper {
  background: #fff;
  display: none;
}
body.home div.announce-wrapper p#announce {
  color: #000;
  padding: 10px 5px;
  margin: 0;
  font-size: 16px;
}
@media (max-width: 991px) {
  body.home div.announce-wrapper p#announce {
    font-size: 14px;
  }
}
body.home div.announce-wrapper p#announce a {
  color: #3c9ee5;
  text-decoration: underline;
  text-decoration-color: #fff;
}
body.home div.announce-wrapper p#announce strong {
  font-style: italic;
  font-weight: 500;
}

html.touchevents body.home .container {
  max-width: 650px;
}
@media (min-width: 1024px) {
  html.touchevents body.home .container {
    max-width: 450px;
  }
}
html.touchevents body.home div.container.game-container {
  margin-top: 20px;
}
@media (max-width: 320px) {
  html.touchevents body.home div.container.game-container {
    margin-top: 10px;
  }
}
html.touchevents body.home div.intro-wrapper {
  margin: 0 -15px;
  padding: 0 15px;
}
html.touchevents body.home div.intro-wrapper p#intro {
  padding: 10px 0;
}
html.touchevents body.home div.attribution-wrapper {
  padding: 0 15px;
}
html.touchevents body.home div.attribution-wrapper p#attribution {
  padding: 10px 0;
}
html.touchevents body.home div.announce-wrapper {
  margin: 0 -15px;
  padding: 0 15px;
}
html.touchevents body.home div.announce-wrapper p#announce {
  padding: 10px 0;
}
@media screen and (max-height: 450px) and (orientation: landscape) {
  html.touchevents body.home div#rotate-notice {
    z-index: 2000;
    display: flex;
  }
}

html.no-touchevents div#rotate-notice {
  display: none;
}
html.no-touchevents div.container.game-container {
  margin-top: 60px;
}

div.link {
  color: #3c9ee5;
  text-decoration: underline;
  -webkit-transition: text-decoration-color 0.3s ease-in-out;
  -moz-transition: text-decoration-color 0.3s ease-in-out;
  -o-transition: text-decoration-color 0.3s ease-in-out;
  transition: text-decoration-color 0.3s ease-in-out;
}
div.link:hover, div.link:focus, div.link:active {
  text-decoration-color: #000;
}

body.faq .question {
  font-weight: bold;
  margin-top: 20px;
}
body.faq .answer {
  margin-bottom: 10px;
}

body.privacy .underline {
  border-bottom: 1px solid #CCCCCC;
}
body.privacy .question {
  font-weight: bold;
  margin-top: 20px;
}
body.privacy .answer {
  margin-bottom: 10px;
}

body.contact {
  /* Add a background color and some padding around the form */
  /* Style the submit button with a specific background color etc */
  /* When moving the mouse over the submit button, add a darker green color */
}
body.contact label {
  color: #000;
  font-size: 18px;
}
body.contact .mobile {
  display: none;
}
body.contact .feedback-alert {
  padding: 10px;
  background: transparent;
  color: #fff;
  margin-bottom: 10px;
  display: none;
}
body.contact .alert-success {
  background: #73bc9e;
  display: block;
}
body.contact .alert-error {
  background: #d94c22;
  display: block;
}
body.contact .contact-form {
  width: 50%;
  padding-top: 20px;
  padding-bottom: 20px;
  margin: auto;
}
body.contact .error {
  color: #d94c22;
  display: block;
  padding: 6px 0;
  font-size: 14px;
}
body.contact .contact-form.error {
  border-color: #d94c22;
  padding: 0.375rem 0.75rem;
}
body.contact .helptext {
  padding-top: 10px;
  padding-bottom: 20px;
}
body.contact .contactInput {
  width: 100%; /* Full width */
  padding: 12px; /* Some padding */
  border: 1px solid #646464; /* Gray border */
  background-color: #f0f0f0;
  box-sizing: border-box; /* Make sure that padding and width stays in place */
  margin-top: 6px; /* Add a top margin */
  margin-bottom: 16px; /* Bottom margin */
  resize: vertical; /* Allow the user to vertically resize the textarea (not horizontally) */
  font-size: 14px;
}
body.contact input[type=submit] {
  background-color: #f0f0f0;
  color: #000;
  border: 1px solid #000;
  padding: 12px 20px;
  cursor: pointer;
}
body.contact input[type=submit]:hover {
  background-color: #000;
  color: #fff;
}
body.contact .required {
  color: #d94c22;
  font-size: 18px;
}

@media (max-width: 767px) {
  body.contact .contact-form {
    width: 100%; /* Full width */
  }
}
div#search-wrapper {
  margin-top: 10px;
  position: relative;
}
div#search-wrapper div.input-feedback {
  position: relative;
  overflow: hidden;
}
div#search-wrapper div.input-feedback > div.inner {
  position: relative;
  padding: 10px 5px;
  -webkit-transition: all 0.15s ease-in-out;
  -moz-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  -o-transform: translateY(100%);
  transform: translateY(100%);
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
}
div#search-wrapper div.input-feedback.found > div.inner {
  background: #73bc9e;
}
div#search-wrapper div.input-feedback.not-found > div.inner {
  background: #d39732;
}
div#search-wrapper div.input-feedback.misspelled > div.inner {
  background: #d94c22;
}
div#search-wrapper div.input-feedback.active > div.inner {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
}
div#search-wrapper div.input-feedback p {
  color: #fff;
  text-align: center;
  font-size: 16px;
  margin: 0;
}
div#search-wrapper form#search-form {
  margin-bottom: 20px;
}
div#search-wrapper .input.word-input-field {
  display: block;
  width: 100%;
  border: none;
  outline: solid 1px transparent;
  background: #f0f0f0;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
  text-align: center;
  font-size: 26px;
  padding: 5px 10px;
  -webkit-transition: outline 0.3s ease-in-out;
  -moz-transition: outline 0.3s ease-in-out;
  -o-transition: outline 0.3s ease-in-out;
  transition: outline 0.3s ease-in-out;
}
div#search-wrapper .input.word-input-field::-webkit-input-placeholder {
  color: #b9b9b9;
}
div#search-wrapper .input.word-input-field::-moz-placeholder {
  color: #b9b9b9;
}
div#search-wrapper .input.word-input-field:-ms-input-placeholder {
  color: #b9b9b9;
}
div#search-wrapper .input.word-input-field:-moz-placeholder {
  color: #b9b9b9;
}
div#search-wrapper .input.word-input-field:hover, div#search-wrapper .input.word-input-field:focus, div#search-wrapper .input.word-input-field:active {
  outline: solid 1px #646464;
}
@media (max-width: 320px) {
  div#search-wrapper .input.word-input-field {
    font-size: 24px;
  }
}
div#search-wrapper button#userWordBtn {
  width: 120px;
  font-size: 24px;
  padding: 2px 10px;
}
div#search-wrapper button#userWordBtn.wobble {
  -webkit-animation: wobble 0.8s both;
  animation: wobble 0.8s both;
}

html.touchevents div#search-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  z-index: 100;
  background: #fff;
  padding: 0 5px;
  max-width: 650px;
}
@media (min-width: 1024px) {
  html.touchevents div#search-wrapper {
    max-width: 450px;
  }
}
html.touchevents div#search-wrapper form#search-form {
  margin-bottom: 0;
}
html.touchevents div#search-wrapper .input.word-input-field:hover, html.touchevents div#search-wrapper .input.word-input-field:focus, html.touchevents div#search-wrapper .input.word-input-field:active {
  outline: none;
}
html.touchevents div#search-wrapper span.desktop-search-submit {
  display: none;
}
html.touchevents div#search-wrapper div.input-feedback {
  position: absolute;
  width: calc(100% - 10px);
  left: 5px;
  bottom: 100%;
}
html.touchevents div#search-wrapper div.input-feedback div.inner {
  padding: 11px 5px;
}
html.touchevents div#search-wrapper div.input-feedback p {
  font-size: 18px;
}
@media (max-width: 320px) {
  html.touchevents div#search-wrapper div.input-feedback p {
    font-size: 16px;
  }
}

div.keyboard-wrapper {
  margin: 0 -5px;
}
div.keyboard-wrapper div.simple-keyboard.hg-theme-default {
  background: #fff;
  padding: 10px 5px;
}
div.keyboard-wrapper div.simple-keyboard.hg-theme-default div.wrodeo-keyboard-button {
  background: #646464;
  color: #fff;
  font-weight: 400;
  font-family: "Helvetica", "Arial", sans-serif;
  font-size: 24px;
  height: 45px;
}
div.keyboard-wrapper div.simple-keyboard.hg-theme-default div.wrodeo-keyboard-button.hg-activeButton {
  background: #000;
}
@media (max-width: 320px) {
  div.keyboard-wrapper div.simple-keyboard.hg-theme-default div.wrodeo-keyboard-button {
    font-size: 18px;
    height: 36px;
  }
}

.phraseotheday {
  padding: 0;
  margin: auto;
  font-size: 18px;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.phraseotheday .word {
  margin: 0 5px 5px 0;
  background-color: #f0f0f0;
  padding: 3px;
  line-height: 1.4;
  cursor: pointer;
  letter-spacing: 2px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  /*@media(max-width: 520px) {
    font-size: 18px;
    letter-spacing: 1px;
  }*/
}
.phraseotheday .word.done {
  background: #fff;
  cursor: default;
  border: none;
}
.phraseotheday .word.hyphen {
  margin-right: 0.25em;
}
.phraseotheday .word.toggle * { /* all children */
  background-color: transparent;
}
.phraseotheday .word.toggle {
  background-color: #87CEFA;
}
.phraseotheday .word.highlight_found {
  background-color: #73bc9e;
  color: #fff;
}
.phraseotheday .word.highlight_found span.cheated {
  color: #fff;
}
.phraseotheday .word.highlight_exsits {
  background-color: #87CEFA;
  color: #fff;
  animation: fadeout 1s forwards;
  animation-delay: 2s;
}
.phraseotheday .word.highlight_surrender {
  background-color: lightcoral;
  animation: fadeout 1s forwards;
  animation-delay: 2s;
}
.phraseotheday .word.highlight_completed {
  background-color: #90EE90;
  animation: fadeout 1s forwards;
  animation-delay: 2s;
}
.phraseotheday .word .surrendered {
  text-decoration: underline;
}
.phraseotheday .word.cheated {
  color: #e1624b;
}
.phraseotheday .word span.cheated {
  -webkit-transition: color 0.3s ease-in-out;
  -moz-transition: color 0.3s ease-in-out;
  -o-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;
  color: #e1624b;
}
@media screen and (max-height: 700px) and (orientation: portrait) {
  .phraseotheday .word {
    font-size: 16px;
    letter-spacing: 1px;
  }
}
@media (max-width: 320px) {
  .phraseotheday .word {
    font-size: 16px;
    letter-spacing: 1px;
    margin: 0 2px 2px 0;
    padding: 2px;
    line-height: 1.2;
  }
}

ul.difficulty-level {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  flex-wrap: wrap;
  align-items: center;
}
ul.difficulty-level li {
  flex: 1;
}
ul.difficulty-level li + li {
  margin-left: 10px;
}
ul.difficulty-level button {
  padding: 2px 5px;
  margin: 0;
  text-align: center;
  border: solid 1px #646464;
  background: #f0f0f0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 100%;
}
ul.difficulty-level button:hover, ul.difficulty-level button:focus, ul.difficulty-level button:active, ul.difficulty-level button.active {
  border-color: #000;
}
ul.difficulty-level button.active {
  background: #000;
  color: #fff;
  cursor: default;
}

.notifyjs-corner {
  left: 0 !important;
  right: 0 !important;
  width: 320px;
  margin: 5px auto !important;
}
.notifyjs-corner .notifyjs-wrapper, .notifyjs-corner .notifyjs-container {
  margin: 0;
}
.notifyjs-corner .notifyjs-bootstrap-error {
  background-image: none;
  padding: 8px 15px !important;
  color: #000;
  background-color: #fff;
  border-color: #d94c22;
  text-align: center;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  animation: none;
  font-weight: 400;
}

body, html {
  overflow-x: hidden;
}

@media (max-width: 320px) {
  .container {
    padding-left: 10px;
    padding-right: 10px;
  }
  .row {
    margin-left: -10px;
    margin-right: -10px;
  }
}
span.splitter-line {
  display: block;
  width: 100%;
  height: 1px;
  background: #f0f0f0;
  margin: 8px 0;
}